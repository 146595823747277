

export const WelcomeScreen = ({ username }) => {
    return (
        <div>
            <h1>Welcome </h1>
            {username
                ? <h1>{username}</h1>
                : <h2>Please Login</h2>
            }

        </div>
    );
};
