
import React, { useState } from "react";
import { register } from "../services/authService";
import { AuthContext } from '../contexts/authContext'
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ButtonGroup } from "@mui/material";

import { toast } from "react-toastify";

export const Register = (props) => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [hasKey, setHasKey] = useState(false);


    const { userLogin, } = useContext(AuthContext);
    const navigate = useNavigate();


    const handleRegister = async (e) => {
        e.preventDefault();
        if (!email || !password || !username) {
            toast.error("Fill in the fields");
            return;
        }
        try {
            const userCredential = await register(email, password, username, hasKey)
            userLogin(userCredential)
            navigate('/')
        } catch (error) {
            toast.error(error.message)
        }
    };

    const defaultTheme = createTheme();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Register
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleRegister} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="username"
                                    name="username"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    autoFocus
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="Do you have a key?"
                  onChange={(e) => setHasKey(true)}
                />
              </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        <Grid container>
                            <ButtonGroup>

                                <Grid item >
                                    <Button variant="body2" onClick={() => toast.error("Not available anymore. Please login", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })} title="You will be unable to edit the calendar">
                                        Continue as guest
                                    </Button >
                                </Grid>
                                <Grid item>
                                    <Button variant="body2" onClick={() => navigate("/login")}>
                                        Already have an account?
                                    </Button>
                                </Grid>
                            </ButtonGroup>
                        </Grid>
                    </Box>
                </Box>

            </Container>
        </ThemeProvider>
    );
};