import './App.css';
import { AuthProvider } from './contexts/authContext'

import { MyCalendar } from './Components/Calendar'
import { Login } from './Components/Login';
import { Register } from './Components/Register';
import { Routes, Route} from 'react-router-dom'
import { Logout } from './Components/Logout';
// import { Checkup } from './Components/Checkup'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function App() {

  return (
    <AuthProvider>
      
      <Routes>
        {/* <Route path="/checkup" element={<Checkup />} /> */}
        <Route path="/" element={<MyCalendar />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<MyCalendar/>} />
      </Routes>
      <ToastContainer {...toastOptions}/>
    </AuthProvider>
  );
}

export default App;
