import { NavLink } from "react-router-dom";
// import { useContext } from "react";
// import { AuthContext } from "../contexts/authContext";
import './header.css'

export const Header = ({user, isAuthenticated}) => {

    // const { isAuthenticated, user } = useContext(AuthContext);

    return (
        <header >
            {!isAuthenticated ?
                <ul>
                    <li>
                        <NavLink to="/login">Login</NavLink>
                    </li>
                    <li>
                        <NavLink to="/register">Register</NavLink>
                    </li>
                </ul>
                :
                <ul>
                    <div>Ще ходиш ли в офиса, {user.username}?</div>
                    <NavLink to="/logout" className={"logout"}>Logout</NavLink>
                </ul>
            }
        </header>
    )
};