import { initializeApp } from "firebase/app";
import  { getAuth, GoogleAuthProvider } from "firebase/auth"
import { getFirestore} from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyCPRjGOCrtUg4heqD2Qtwe_hAQS54hwrI8",
  authDomain: "hqpresence.firebaseapp.com",
  projectId: "hqpresence",
  storageBucket: "hqpresence.appspot.com",
  messagingSenderId: "670362967800",
  appId: "1:670362967800:web:d6f827ff154f85fc9f650d",
  measurementId: "G-9LG4PD12MM"
};

const app = initializeApp(firebaseConfig, "hqpresence");

export const googleProvider = new GoogleAuthProvider();
export const auth = getAuth(app)
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
