import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../contexts/authContext";

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment-timezone";

import { CSSTransition } from 'react-transition-group';
import "./CSStransitions.css"

import { query, where, setDoc, deleteDoc, doc, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { WelcomeScreen } from "./WelcomeScreen";
import { Header } from './Header';


import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase-config";
import { toast } from "react-toastify";
// import { Skeleton } from "@mui/material";
import { FormDialog } from './Dialog'
import { Login } from "./Login";

export const MyCalendar = (props) => {
    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [slotDisabled, setSlotDisabled] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(moment().month());
    const [currentYear, setCurrentYear] = useState(moment().year());
    const [showWelcome, setShowWelcome] = useState(true);



    const { user, isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        // Use setTimeout to hide the welcome screen after 2 seconds
        const timer = setTimeout(() => {
            setShowWelcome(false);
        }, 2500);

        // Clean up the timer when the component unmounts
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        logEvent(analytics, "page_view", { page: "calendar" });
    }, []);

    //   useEffect(() => {
    //     toast.info(
    //     <>
    //     Due to some recent updates, you may need to <b> Logout </b> and then <b> Login </b> again.

    //   </>,
    //   {
    //     position: "top-center",
    //     autoClose: 12000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   }
    // );
    //   }, []);

    // useEffect(() => {
    //   // Display initial toast message after 4 seconds
    //   const initialToastTimeout = setTimeout(() => {
    //       toast.info(
    //      'Съжалявам за причиненото неудобство',
    //           {
    //             position: "top-center",
    //             autoClose: 6000,
    //             hideProgressBar: true,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //           }
    //         );
    //   }, 4000);

    //   // Clean up the timeout when the component unmounts
    //   return () => clearTimeout(initialToastTimeout);
    // }, []);




    useEffect(() => {
        const fetchEvents = async () => {
            setLoading(true);

            const startOfMonth = moment().month(currentMonth).year(currentYear).startOf("month").toDate();
            const endOfMonth = moment().month(currentMonth).year(currentYear).endOf("month").toDate();

            const eventsCollection = collection(db, "events");
            const q = query(eventsCollection, where("start", ">=", startOfMonth), where("start", "<=", endOfMonth));
            const eventsSnapshot = await getDocs(q);

            const transformedData = eventsSnapshot.docs.map((doc) => ({
                start: moment(doc.data().start.toDate()).toDate(),
                end: moment(doc.data().end.toDate()).toDate(),
                title: doc.data().title,
                id: doc.id,
                comment: doc.data().comment,
            }));

            const sortedData = transformedData.sort((a, b) => a.title.localeCompare(b.title));

            setEvents(sortedData);
            setLoading(false);
        };

        fetchEvents();
    }, [currentMonth, currentYear]);




    //   console.log(events);

    moment.locale("bg");
    moment.tz.setDefault("Europe/Sofia");
    moment.updateLocale("bg", {
        week: {
            dow: 1,
        },
    });

    const localizer = momentLocalizer(moment);

    const handleSelectEvent = async (event, comment) => {
        if (event.title.includes(user.username)) {
            // const comment = window.prompt("Add a comment");
            console.log("Thevent is", event, comment);
            setOpenDialog(true);
            setSelectedEvent(event);
            const updatedComment = typeof comment === 'object' ? '' : comment;
            const updatedEvent = { ...event, comment: updatedComment };

            const updatedEvents = events.map((e) =>
                e.id === event.id ? updatedEvent : e
            );

            setEvents(updatedEvents);

            setEvents(updatedEvents);
            try {
                const eventRef = doc(db, "events", updatedEvent.id);
                await setDoc(eventRef, updatedEvent);
            } catch (error) {
                toast.error(error.message);
                toast.info(
                    <>
                        Due to some recent updates, you may need to <b> Logout </b> and then <b> Login </b> again.
                    </>,
                    {
                        position: "top-center",
                        autoClose: 12000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
            }
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedEvent(null);
    };

    const handleSelectSlot = async ({ start }) => {
        if (slotDisabled) {
            return;
        }

        if (!isAuthenticated) {
            toast.error("Sign in to edit the calendar");
            return;
        }

        const selectedDate = moment(start).format("YYYY-MM-DD");
        const eventId = `${selectedDate}-${user.username}`;
        const existingEvent = events.find((event) => event.id === eventId);

        if (existingEvent) {
            setEvents(events.filter((event) => event.id !== eventId));
            const eventRef = doc(db, "events", eventId);
            await deleteFirestoreEvent(eventRef, user.username);
        } else {
            setSlotDisabled(true);
            let title = user.username;

            if (user?.hasKey) {
                title = `🗝️${user.username} `;
            }

            const newEvent = {
                id: eventId,
                start,
                date: selectedDate,
                end: moment(start).add(1, "hour").toDate(),
                title: title,
            };

            setSelectedDate(start);
            setEvents([...events, newEvent]);

            await addEventToFirestore(newEvent, eventId);

            setTimeout(() => {
                setSlotDisabled(false);
            }, 500);
        }
    };

    const addEventToFirestore = async (event, eventId) => {
        try {
            const eventRef = doc(db, "events", eventId);
            await setDoc(eventRef, event);
        } catch (error) {
            toast.error(error.message);
            toast.info(
                <>
                    Due to some recent updates, you may need to <b> Logout </b> and then <b> Login </b> again.
                </>,
                {
                    position: "top-center",
                    autoClose: 12000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                }
            )
        }
    };

    const deleteFirestoreEvent = async (event) => {
        try {
            await deleteDoc(doc(db, "events", event.id));
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleNavigate = (date) => {
        setCurrentMonth(moment(date).month());
        setCurrentYear(moment(date).year());
    };



    return (
        <div>
            <CSSTransition
                in={showWelcome}
                timeout={500} // Adjust the duration to match your CSS transition duration
                classNames="fade" // The CSS class prefix for your animations
                unmountOnExit
            >
                <div className="welcome-screen">

                    <WelcomeScreen username={user.username} />
                </div>
            </CSSTransition>

            <CSSTransition
                in={!showWelcome}
                timeout={1000} // Adjust the duration to match your CSS transition duration
                classNames="fade" // The CSS class prefix for your animations
                unmountOnExit
            >
                {isAuthenticated
                  ? ( < div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '90%' }}>
                    <Header user={user} isAuthenticated={isAuthenticated} />
                    <BigCalendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        titleAccessor="title"
                        popup
                        selectable="true"
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={handleSelectEvent}
                        views={{
                            month: true,
                        }}
                        components={{
                            event: ({ event }) => (
                                <div>
                                    {event.title}{' '}
                                    {event.comment && (
                                        <span title={event.comment} style={{ color: 'red' }}>
                                            {event.comment}
                                        </span>
                                    )}
                                </div>
                            ),
                        }}
                        style={{ height: 800, lineHeight: '1.2em' }}
                        onNavigate={handleNavigate}
                    />
                    <FormDialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        selectedEvent={selectedEvent}
                        handleSelectEvent={handleSelectEvent}
                    />
                </div>
        </div>) 
        : <Login/>
                        }
            </CSSTransition >


        </div >
    );





    // return (
    //     {showWelcome ? (
    //         <WelcomeScreen />
    //       ) : (

    //     <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    //         <div style={{ width: "90%" }}>
    //             <BigCalendar
    //                 localizer={localizer}
    //                 events={events}
    //                 startAccessor="start"
    //                 endAccessor="end"
    //                 titleAccessor="title"
    //                 popup
    //                 selectable="true"
    //                 onSelectSlot={handleSelectSlot}
    //                 onSelectEvent={handleSelectEvent}
    //                 views={{
    //                     month: true,
    //                 }}
    //                 components={{
    //                     event: ({ event }) => (
    //                         <div>
    //                             {event.title}{" "}
    //                             {event.comment && (
    //                                 <span title={event.comment} style={{ color: "red" }}>
    //                                     {event.comment}
    //                                 </span>
    //                             )}
    //                         </div>
    //                     ),
    //                 }}
    //                 style={{ height: 800, lineHeight: "1.2em" }}
    //                 onNavigate={handleNavigate}
    //             />
    //             <FormDialog
    //                 open={openDialog}
    //                 onClose={handleCloseDialog}
    //                 selectedEvent={selectedEvent}
    //                 handleSelectEvent={handleSelectEvent}
    //             />
    //         </div>
    //     </div>
    //     );
    // };
    // );

};