import { db, auth, googleProvider } from "../firebase-config"
import {
  createUserWithEmailAndPassword, 
  signInWithPopup, 
  signInWithEmailAndPassword, 
  signOut,
  sendPasswordResetEmail
} from "firebase/auth";
import { getDoc, doc, setDoc, collection } from "firebase/firestore";


const usersCollectionRef = collection(db, "users");

export const register = async (email, password, username, hasKey) => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    const userDocRef = doc(usersCollectionRef, user.uid);

    const desiredData = {
      username: username,
      hasKey: hasKey,
      signedWithGoogle: false,
    };

    await setDoc(userDocRef, desiredData);

    return { _id: user.uid, ...desiredData };

  } catch (error) {
    throw new Error(error.message);
  }
};

export const login = async (email, password) => {
  try {
    const loginInfo = await signInWithEmailAndPassword(auth, email, password)
    const userDocRef = doc(usersCollectionRef, loginInfo.user.uid);
    const userSnap = await getDoc(userDocRef);
    const userSnapData = userSnap.data();
    return {
      ...userSnapData,
      _id: loginInfo.user.uid,
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const googleSignIn = async () => {
    try {
      const googleSignInInfo = await signInWithPopup(auth, googleProvider);
      const googleInfo = {
        username: googleSignInInfo.user.displayName.split(' ')[0],
       
        _id: googleSignInInfo.user.uid,
        email: googleSignInInfo.user.email,
        signedWithGoogle: true,
      };
  
      const userDocRef = doc(usersCollectionRef, googleInfo._id);
      
     
      await setDoc(userDocRef, googleInfo);
  
      const userSnap = await getDoc(userDocRef);
      const userSnapData = userSnap.data();
      console.log(userSnapData);
      return {
        ...userSnapData,

      };
    } catch (error) {
      throw new Error(error.message);
    }
  };

  export const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throw new Error(error.message);
    }
  };
  

  export const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throw new Error(error.message);
    }
  };