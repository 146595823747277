import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const FormDialog = ({ open, onClose, handleSelectEvent, selectedEvent }) => {
  const [comment, setComment] = useState("");
  console.log(selectedEvent);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSelectEvent(selectedEvent, comment);
    onClose();
  };
  //something to do with passing the comment and the event!!!!!!!!!!!!!!!!!!
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add a Comment</DialogTitle>

      <DialogContent>
        <form onSubmit={handleFormSubmit}>
          <TextField
            label="Comment"
            value={comment}
            onChange={handleCommentChange}
            fullWidth
            rows={1}
          />
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

