import { useState } from "react";
import { login, resetPassword, googleSignIn } from "../services/authService";
import { AuthContext } from '../contexts/authContext'
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ButtonGroup } from "@mui/material";

import {  toast } from 'react-toastify';

export const Login = (props) => {

    const { userLogin } = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();


    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password){
            toast.error("Enter email and password");
            return;
        }
        try {
            const userCredential = await login(email, password);
            userLogin(userCredential);
            navigate('/');
        } catch (error) {
            toast.error("Invalid usename or password")
        }
    };

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();

       try {
         const userCredential = await googleSignIn();
         console.log(userCredential);
         userLogin(userCredential);
         navigate('/');
       } catch (error) {
        toast.error(error.message)
       }
    }

    const handleForgotPassword = async () => {
        if(!email){
            toast.error("Please enter an email")
            return
        }
        try {
            await resetPassword(email);
            toast.success(`Reset email sent to ${email}`);
        } catch (error) {
            toast.error(`Error: ${error}`);
        }
    };

    const defaultTheme = createTheme();


    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 0 }}
                        >
                            Sign In
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 2 }}
                            onClick={handleGoogleSignIn}
                        >
                            Sign In with Google
                        </Button>
                        <Grid container>
                            <ButtonGroup>

                                <Grid item >
                                    <Button variant="body2" onClick={handleForgotPassword}>
                                        Forgot password?
                                    </Button >
                                </Grid>
                                <Grid item>
                                    <Button variant="body2" onClick={() => navigate("/register")}>
                                        Don't have an account?
                                    </Button>
                                </Grid>
                            </ButtonGroup>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};


